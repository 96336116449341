import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

import '../stylesheets/components/Nav.css'


class Header extends Component{
  constructor(props) {
    super(props);

    this.state = {
      dropdownPortfolioOpen: false,
      dropdownMoreOpen: false,
      dropdownPortfolioActive: '',
      dropdownMoreActive: '',
      smc: false
    };

    this.togglePortfolio = this.togglePortfolio.bind(this);
    this.toggleMore = this.toggleMore.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getDropdownMenu = this.getDropdownMenu.bind(this);
  }

  handleActive = (pathname, name) => {
    const re = new RegExp(`/${name}/`);
    const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
    if(re.test(pathname)) {
      this.setState({ [`dropdown${capitalized}Active`]: 'active' })
    } else {
      this.setState({ [`dropdown${capitalized}Active`]: '' })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', function() {
      if(window.scrollY > 50) {
        document.getElementsByTagName('header')[0].style.boxShadow = '1px 1px 5px rgba(0,0,0,0.3)';
      } else {
        document.getElementsByTagName('header')[0].style.boxShadow = '1px 1px 5px rgba(0,0,0,0)';
      }
    })

    this.unlistenPortfolio = this.props.history.listen(res => {
      this.handleActive(res.pathname, 'portfolio');
      this.handleActive(res.pathname, 'more');
    })

    this.handleActive(window.location.pathname, 'portfolio');
    this.handleActive(window.location.pathname, 'more');
  }

  componentWillUnmount() {
    this.unlistenPortfolio()
    this.unlistenMore()
  }

  togglePortfolio() {
    this.setState({
      dropdownPortfolioOpen: !this.state.dropdownPortfolioOpen
    });
  }

  toggleMore() {
    this.setState({
      dropdownMoreOpen: !this.state.dropdownMoreOpen
    });
  }

  handleSelect(s = '') {
    let pattern = '^/portfolio/' + s;
    return (new RegExp(pattern, 'g').test(window.location.pathname)) ? 'd-none' : '';
  }

  getDropdownMenu(portfolioList) {
    return (
        <DropdownMenu>
          {portfolioList.map((i, index) => (
            <DropdownItem className={ this.handleSelect(i.title.replace(/\s/g, '-')) } key={i.title + index}>
              <Link to={`/portfolio/${i.title.replace(/\s/g, '-')}`}>{i.title}</Link>
            </DropdownItem>
          ))}
        </DropdownMenu>
    )
  }

  DropDownMenuMore = () => {
    return (
      <DropdownMenu>
        <DropdownItem header className=''>
          D3
        </DropdownItem>
        <DropdownItem divider />
          <DropdownItem className='d3'>
            <Link to='/more/d3/arc'>Arc</Link>
          </DropdownItem>
          <DropdownItem className='d3'>
            <Link to='/more/d3/bar'>Bar</Link>
          </DropdownItem>
          <DropdownItem className='d3'>
            <Link to='/more/d3/bubble'>Bubble</Link>
          </DropdownItem>
          <DropdownItem className='d3'>
            <Link to='/more/d3/tree'>Tree</Link>
          </DropdownItem>
          <DropdownItem className='d3'>
            <Link to='/more/d3/treemap'>Treemap</Link>
          </DropdownItem>
          <DropdownItem className='d3'>
            <Link to='/more/d3/map'>Map</Link>
          </DropdownItem>
          <DropdownItem className='d3'>
            <Link to='/more/d3/histogram'>Histogram</Link>
          </DropdownItem>
      </DropdownMenu>
    )
  }

  render() {
    const { dropdownPortfolioActive, dropdownMoreActive } = this.state;
    const { portfolioList } = this.props;
    return (
        <header className="Nav container-fluid">
          <div className="main_header">
            <div className="row">
              <div className="col-sm-6 logo_wrap">
                <Link to="/">
                  <div className="logo ml-md-5">&nbsp;</div>
                </Link>
              </div>
              <div className="col-sm-6 nav_wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink to="/" exact>
                      <FormattedMessage id="Header.home" defaultMessage="Home" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/about">
                      <FormattedMessage id="Header.about" defaultMessage="About Sam" />
                    </NavLink>
                  </NavItem>
                  <Dropdown nav isOpen={ this.state.dropdownPortfolioOpen } toggle={ this.togglePortfolio } className={dropdownPortfolioActive}>
                    <DropdownToggle nav caret>
                      <FormattedMessage id="Header.portfolio" defaultMessage="Portfolio" />
                    </DropdownToggle>
                    { this.getDropdownMenu(portfolioList) }
                  </Dropdown>
                  <Dropdown nav isOpen={ this.state.dropdownMoreOpen } toggle={ this.toggleMore } className={dropdownMoreActive}>
                    <DropdownToggle nav caret>
                      <FormattedMessage id="Header.more" defaultMessage="More" />
                    </DropdownToggle>
                    { this.DropDownMenuMore() }
                  </Dropdown>
                </Nav>
              </div>
            </div>
          </div>
        </header>
    )
  }
}

export default withRouter(Header);
