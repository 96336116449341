import React, { Component, Fragment } from 'react';
import { FormattedMessage } from "react-intl";

export default class ContestDetails extends Component{
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <Fragment>
          <h2 className="f30 semi_bold">
            <FormattedMessage id="Project.details" defaultMessage="Contest Details" />
          </h2>
          <ul>
            {
              this.props.list.map((res, index) => {
                return <li className="f16 standard" key={ index }>{ (this.props.locale === 'en') ? res.item.en : res.item.zh }</li>
              })
            }
          </ul>
        </Fragment>
    )
  }
}
