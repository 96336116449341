import React, { useEffect } from 'react';
import * as d3 from 'd3';

const Bar = () => {
    const barHeight = 30;
    const barSpace = 10;
    const barTotalHeight = barHeight + barSpace;
    const padding = 20;
    const fontSize = '1.3em';

    useEffect(() => {  
        const width = document.querySelector('.container.main_content').clientWidth;

        d3.json('https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json').then((data, error) => {
            if (error) throw error;

            const dataArr = data.children.filter(i => i.name === 'DS')[0].children.map(i => ({...i, value: parseInt(i.value * 3)}));
            
            const totalHeight = dataArr.length * barTotalHeight;
            const HEIGHT = totalHeight + 100;

            const maxVal = d3.max(dataArr.map(i => i.value));

            const widthScale = d3.scaleLinear()
                .domain([0, maxVal])
                .range([0, width - padding * 3])

            const colorScale = d3.scaleLinear()
                .domain([0, maxVal])
                .range(['red', 'blue'])

            const xAxis = d3.axisBottom(widthScale)

            const canvas = d3.select('div#bar-container')
                .html('')
                .append('svg')
                    .attr('width', width)
                    .attr('height', HEIGHT)
                    .append('g')
                        .attr('transform', `translate(${padding}, ${padding})`)
            
            // bars
            canvas.selectAll('rect')
                .data(dataArr)
                .join(
                    enter => enter
                        .append('rect')
                        .attr('height', barHeight)
                        .attr('fill', d => colorScale(d.value))
                        .attr('y', (_, index) => index * barTotalHeight)
                        .attr('width', d => widthScale(0)),
                    update => update,
                    exit => exit
                        .remove()
                )
                .transition().duration(1000)
                    .attr('width', d => widthScale(d.value))

            // values
            canvas.selectAll('text.item-value')
                .data(dataArr)
                .join(
                    enter => enter
                        .append('text')
                        .attr('class', 'item-vale')
                        .attr('font-size', fontSize)
                        .attr('transform', (d, i) => `translate(5, ${barHeight * 0.6 + i * barTotalHeight})`)
                        .attr('text-anchor', 'start')
                        .text(0),
                    update => update,
                    exit => exit.remove()
                )
                .transition().duration(1000)
                    .attr('transform', (d, i) => `translate(${widthScale(d.value) + 5}, ${barHeight * 0.6 + i * barTotalHeight})`)
                    .tween('text', function (d) {
                        return function (t) {
                            d3.select(this).text(Math.floor(t * d.value))
                        }
                    })

            // text
            canvas.selectAll('text.item-name')
                .data(dataArr)
                .enter()
                    .append('text')
                    .attr('font-size', fontSize)
                    .attr('fill', 'white')
                    .attr('text-anchor', 'start')
                    .attr('class', 'item-name')
                    .attr('transform', (_, i) => `translate(5, ${barHeight * 0.6 + i * barTotalHeight})`)
                    .text(d => d.name)

            // label
            canvas.append('text')
                .attr('transform', `translate(${width / 2}, ${totalHeight + 35})`)
                .attr('text-anchor', 'middle')
                .text('Sales')

            // axis
            canvas.append('g')
                .attr('transform', `translate(0, ${totalHeight})`)
                .call(xAxis) 
        })
    })
    
    return(
        <div id="bar-container"/>
    )
}

export default Bar;
