import React, {Component} from 'react';
import { fetchCovid } from "../action";
import SearchInput, {createFilter} from 'react-search-input'

import '../stylesheets/views/COVID.css';

class Covid extends Component {
  static defaultProps = {
    select: [
      { name: 'United State', value: 'US' },
      { name: 'Taiwan', value: 'Taiwan' },
      { name: 'China', value: 'Mainland China' },
      { name: 'Japan', value: 'Japan' },
      { name: 'South Korea', value: 'South Korea' },
      { name: 'Italy', value: 'Italy' }
    ]
  };

  constructor(props) {
    super(props);
    this.state = {
      country: this.props.select[0].value,
      data: [],
      query: '',
      total: {
        confirmed: 0,
        deaths: 0,
        recovered: 0
      }
    }
  }


  componentDidMount() {
    const { country } = this.state;
    this.handleFetch(country);
  };

  handleFetch = country => {
    let confirmed = 0, deaths = 0, recovered = 0;
    fetchCovid(country).then(data => {
      this.setState({
        data: data.features
      });

      data.features.forEach(i => {
        confirmed += i.attributes.Confirmed;
        deaths += i.attributes.Deaths;
        recovered += i.attributes.Recovered
      });

      this.setState({ total: { confirmed, deaths, recovered }})
    })
  };

  handleSelectChange = evt => {
    this.setState({
      country: evt.target.value
    });
    this.handleFetch(evt.target.value)
  };

  handleSearchChange = query => {
    this.setState({ query })
  };

  handleFilter = (o, sq) => {
    if(sq === '') {
      return o
    }
    return o && o.filter(createFilter(sq, 'attributes.Province_State'))
  };

  render() {
    const { select } = this.props;
    const { country, data, query, total: { confirmed, deaths, recovered } } = this.state;
    const filtered = this.handleFilter(data, query);
    return (
        <section className="container COVID">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="COVID--Head">
                <h2>COVID-19</h2>
                <div className='select_wrap'>
                  <span className='f16'>Country - </span>
                  <select name="" id="" value={country} onChange={this.handleSelectChange}>
                    {select.map((i, index) => (
                        <option key={i.name + index} value={i.value}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='COVID--Total'>
                <p>Total Confirmed: {confirmed} / Deaths: {deaths} / Recovered: {recovered}</p>
              </div>

              <div className="COVID--Content row">
                <div className="col-lg-4 mb-3 f16 d-flex align-items-center">
                  Search: <SearchInput className='search_input' onChange={this.handleSearchChange} />
                </div>

                <div className="col-lg-12" />

                {filtered.map((i, index) => (
                    <div className={data.length === 1 ? 'col-md-12' : 'col-md-6'} key={i.attributes['Province_State'] + index}>
                      <div className="card mb-5">
                        <div className="card-header f16">
                          {i.attributes['Province_State'] || i.attributes['Country_Region']}
                        </div>
                        <div className="card-body">
                          <h5 className="card-title f16">Last Update: {new Date(i.attributes['Last_Update']).toLocaleDateString()}</h5>
                          <p className="card-text">Confirmed: {i.attributes['Confirmed']}</p>
                          <p className="card-text">Deaths: {i.attributes['Deaths']}</p>
                          <p className="card-text">Recovered: {i.attributes['Deaths']}</p>
                          {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
            <p className='txt_l'>sources: <a className='txt_l' href="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6">Coronavirus COVID-19 Global Cases by Johns Hopkins CSSE</a></p>
          </div>
        </section>
    );
  }
}

export default Covid;
