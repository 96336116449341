import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Contact from '../views/Contact';

import '../stylesheets/components/Footer.css';
import {Link} from "react-router-dom";

export default class Footer extends Component{
  constructor(props) {
    super(props);

    this.state = {
      locale: 'en',
      isShow: false
    };

    this.updateLocale = this.updateLocale.bind(this);
    this.handlerChildHideForm = this.handlerChildHideForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  updateLocale(e) {
    if(this._isMounted) {
      this.props.locale(e.target.value);
    }
  }

  handlerChildHideForm() {
    this.hideForm();
  }

  showForm() {
    if(this._isMounted) {
      this.setState({
        isShow: true
      });
    }
  }

  hideForm() {
    if(this._isMounted) {
      this.setState({
        isShow: false
      });
    }
  }

  render() {
    return (
        <footer className="Footer">
          <div className='container-fluid footer_wrap'>
            <div className="row">
              { (this.state.isShow)?<Contact fn={ this.handlerChildHideForm } />:null }
              <div className="col-sm-6 nav_wrap">
                <ul>
                  <li className="no-list"><a href="https://www.linkedin.com/in/samlovesdesign/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                  {/*<li className="no-list"><a href="mailto:samchao1018@gmail.com">*/}
                  {/*<FormattedMessage id="Footer.email" defaultMessage="Email" />*/}
                  {/*</a></li>*/}
                  {/*<li className="no-list"><button className='f18' onClick={ this.showForm }><FormattedMessage id="Footer.email" defaultMessage="Contact Sam" /></button></li>*/}
                  <li className="no-list"><Link to="/contact"><FormattedMessage id="Footer.email" defaultMessage="Contact Sam" /></Link></li>
                </ul>
              </div>
              <div className="col-sm-6 copy_wrap">
                <h3 className="f12 standard text-right">&copy; Sam Chao { this.getCurrentYear() } </h3>
                <select name="lang" id="lang" onChange={ this.updateLocale } value={ this.props.current }>
                  <option value="en">English</option>
                  <option value="zh">中文</option>
                </select>
              </div>
            </div>
          </div>
        </footer>
    )
  }
}
