import React, { Component } from 'react';

import '../stylesheets/views/About.css'

import Skills from '../components/Skills';

export default class About extends Component{
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({top:0});
  }

  render() {
    const { fields : { greeting, desc1, desc2, skills }, locale, exp } = this.props;
    console.log(greeting)
    return(
        <section className="container main_content">
          <div className="about_wrap">
            <div className="row">
              <div className="col-md-3">
                <img src={ '/images/home/sam_avatar.svg' } alt="Sam" />
              </div>
              <div className="col-md-9">
                <h1 className="f30 semi_bold">
                  { (locale === 'en') ? greeting.en : greeting.zh }
                </h1>
                <p>
                  { (locale === 'en') ? desc1.en : desc1.zh } { exp } { (locale === 'en') ? desc2.en : desc2.zh }
                </p>
                <div className="divider-20">&nbsp;</div>
                <h2 className="f30 semi_bold">
                  { locale === 'en' ? 'Skills' : '技術' }
                </h2>
                <div className="divider-10">&nbsp;</div>
                <Skills skills={skills} locale={locale} />

              </div>
            </div>


          </div>
          <div className="divider-20">&nbsp;</div>
        </section>
    )
  };
}
