import React from 'react'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import { Alert } from 'reactstrap'
import {FormattedMessage, injectIntl} from "react-intl";

import '../stylesheets/components/EnquiryForm.css'

const fetch = window.fetch

class Form extends React.Component {
  static defaultProps = {
    name: 'Contact Form Ajax',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Thanks you, we will get back to you soon',
    errorMessage:
        'There is a problem, your message has not been sent, please try contacting us via email'
  }

  state = {
    alert: '',
    success: false,
    disabled: false
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })
    fetch(form.action + '?' + stringify(data), {
      method: 'POST'
    })
        .then(res => {
          if (res.ok) {
            return res
          } else {
            throw new Error('Network error')
          }
        })
        .then(() => {
          form.reset()
          this.setState({
            alert: this.props.successMessage,
            success: true,
            disabled: false
          })
        })
        .catch(err => {
          console.error(err)
          this.setState({
            disabled: false,
            alert: this.props.errorMessage,
            success: false
          })
        })
  }

  handleClose = () => {
    this.setState({alert: ''})
  }

  render () {
    const { name, subject, action, intl: { formatMessage } } = this.props
    const { alert, success } = this.state

    return (
        <form
            className='EnquiryForm'
            name={name}
            action={action}
            onSubmit={this.handleSubmit}
            data-netlify=''
            data-netlify-honeypot='_gotcha'
        >
          <label className='EnquiryForm--Label required'>
            <input
                className='EnquiryForm--Input'
                type='text'
                placeholder={formatMessage({ id: 'Contact.name', defaultMessage: 'Name' })}
                name='name'
                required
            />
          </label>
          <label className='EnquiryForm--Label required'>
            <input
                className='EnquiryForm--Input'
                type='email'
                placeholder={formatMessage({ id: 'Contact.email', defaultMessage: 'Email' })}
                name='email'
                required
            />
          </label>

          <label className='EnquiryForm--Label'>
            <input
                className='EnquiryForm--Input'
                type='text'
                placeholder={formatMessage({ id: 'Contact.company', defaultMessage: 'Company' })}
                name='company'
            />
          </label>

          <label className='EnquiryForm--Label required'>
          <textarea
              className='EnquiryForm--Input EnquiryForm--Textarea'
              placeholder={formatMessage({ id: 'Contact.msg', defaultMessage: 'What can I help you?' })}
              name='message'
              rows='10'
              required
          />
          </label>

          {alert && (
              <Alert className='f12' color={success ? 'primary' : 'danger'} isOpen={alert} toggle={this.handleClose}>{alert}</Alert>
          )}

          <p className='note'><span className='txt_r'>*</span> = <FormattedMessage id="Contact.required" defaultMessage="required" /></p>
          <input type='text' name='_gotcha' style={{ display: 'none' }} />
          {!!subject && <input type='hidden' name='subject' value={subject} />}
          <input type='hidden' name='form-name' value={name} />
          <div className="btn_wrap">
            <input
                className='btn btn_app mr-4'
                type='submit'
                value={formatMessage({ id: 'Contact.submit', defaultMessage: 'Submit' })}
                disabled={this.state.disabled}
            />
            <input
                className='btn btn_hallow'
                type="reset"
                value={formatMessage({ id: 'Contact.clear', defaultMessage: 'Clear' })}
            />
          </div>
        </form>
    )
  }
}

export default injectIntl(Form)
