import React, { Component } from 'react';
import { Progress } from 'reactstrap';

export default class Skills extends Component{
  constructor(props) {
    super(props);

    this.state = {
      skills: [],
      searchVal: ''
    };

    this.handleProgressColor = this.handleProgressColor.bind(this);
    this.handleDel = this.handleDel.bind(this);
    this.filterList = this.filterList.bind(this);
  }

  componentDidMount() {
    this.setState({
      skills: this.props.skills
    });
  }

  handleProgressColor(n) {
    if(n >= 0 && n <= 30) {
      return 'red';
    }
    if(n > 30 && n < 80) {
      return 'blue';
    }
    if(n >= 80 && n <= 100) {
      return 'app';
    }
  }

  filterList(evt) {
    this.setState({
      searchVal: evt.target.value
    });
    const oriList = this.props.skills;
    let updatedList = oriList.filter(function(item){
      return item.title.toLowerCase().search(
          evt.target.value.toLowerCase()) !== -1;
    });
    this.setState({skills: updatedList});
  }

  handleDel(e) {
    e.preventDefault();
    this.setState({
      searchVal: '',
      skills: this.props.skills
    });
  }

  render() {
    const { locale } = this.props;
    return(
        <div className="row">
          <div className="col-lg-12">
            <input className="text_input" type="text" value={ this.state.searchVal } placeholder={ locale === 'en' ? 'Keyword Search' : '關鍵字查詢' } onChange={ this.filterList } />
            <span className="sprite icon close_icon">
              <span className="sprite" onClick={ this.handleDel }>&nbsp;</span>
            </span>
          </div>
          <div className="divider-10">&nbsp;</div>
          { this.state.skills.map((item, index) => {
            return(
                <div className="col-sm-6" key={ index }>
                  <h3 className="f20 regular">{ item.title }</h3>
                  {/*<div className="text-center">{ item.exp}%</div>*/}
                  <Progress color={ this.handleProgressColor(item.rate) } value={ item.rate }>{ item.rate}%</Progress>
                </div>
            )
          })}
        </div>
    )
  }
}
