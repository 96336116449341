import React from 'react';
// import { FormattedMessage } from 'react-intl';
import Arc from '../components/d3/Arc';
import Bar from '../components/d3/Bar';
import Bubble from '../components/d3/Bubble';
import Histogram from '../components/d3/Histogram';
import Tree from '../components/d3/Tree';
import Treemap from '../components/d3/Treemap';
import Map from '../components/d3/Map';


import '../stylesheets/views/D3.css'

const D3 = ({...props}) => {
    const name = props.match.params.name;
    return(
        <section className="container main_content">
            <div className="d3_wrap">
                {name === 'arc' && <Arc />}
                {name === 'bar' && <Bar />}
                {name === 'bubble' && <Bubble />}
                {name === 'histogram' && <Histogram />}
                {name === 'tree' && <Tree />}
                {name === 'treemap' && <Treemap />}
                {name === 'map' && <Map />}
            </div>
            
        </section>
    )

}

export default D3