import React from 'react'

import EnquiryFormContactAjax from '../components/EnquiryFormContactAjax'
import Content from '../components/Content'

import '../stylesheets/views/Contact.css'

export default ({ fields }) => {
  const { body, backgroundImage, email } = fields;
  return (
      <div className='Contact'>
        <div className='container-fluid'>
          <div className='row pb-5'>
            <div className='container'>
              <div className='row pt-5'>
                <div className='col-lg-6'>
                  <div className='info-wrap' style={{backgroundImage: `url(${backgroundImage})`}}>
                    <div className='mask' />
                    <Content source={body} />
                    <a className='email' href={`mailto:${email}?subject=SamLovesDesign: `}>{email}</a>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <EnquiryFormContactAjax name='Request Access Form' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
