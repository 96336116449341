import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'

import ScrollToTop from './components/ScrollToTop'
import Meta from './components/Meta'
import Home from './views/Home'
import About from './views/About'
import Blog from './views/Blog'
import SinglePost from './views/SinglePost'
import Contact from './views/Contact'
import COVID from './views/COVID'
import D3 from './views/D3'
// import CompAndSave from './views/CompAndSave'
// import CreateAThon from './views/CreateAThon'
// import Freelance from './views/Freelance'
// import LiveH2H from './views/LiveH2H'
// import Nimbella from './views/Nimbella'
// import SanMateoCounty from './views/SanMateoCounty'
import Portfolio from './views/Portfolio'
import NoMatch from './views/NoMatch'
import Nav from './components/Nav'
import Footer from './components/Footer'
import ServiceWorkerNotifications from './components/ServiceWorkerNotifications'
import data from './data.json'
import { slugify } from './util/url'
import { documentHasTerm, getCollectionTerms } from './util/collection'

// locale
import { IntlProvider } from 'react-intl';
import enJson from "./util/i18n/English.json";
import zhJson from "./util/i18n/Chinese.json";

const RouteWithMeta = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={routeProps => (
      <Fragment>
        <Meta {...props} />
        <Component {...routeProps} {...props} />
      </Fragment>
    )}
  />
)



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data,
      language: 'en',
      messages: {},
      expYear: Math.floor((new Date().getTime() - 1373846400000)/60/60/24/7/52/1000)
    };

    this.updateLocale = this.updateLocale.bind(this);
    this._isMounted = false;
  }

  getDocument = (collection, name) =>
    this.state.data[collection] &&
    this.state.data[collection].filter(page => page.name === name)[0]

  getDocuments = collection => this.state.data[collection] || []

  componentDidMount() {
    // const Intl = useIntl();
    // if (!Intl.PluralRules) {
    //   require('@formatjs/intl-pluralrules/polyfill');
    //   require('@formatjs/intl-pluralrules/dist/locale-data/zh'); // Add locale data for zh
    // }
    //
    // if (!Intl.RelativeTimeFormat) {
    //   require('@formatjs/intl-relativetimeformat/polyfill');
    //   require('@formatjs/intl-relativetimeformat/dist/locale-data/zh'); // Add locale data for zh
    // }

    const locale = localStorage.getItem('samlovesdesignLocale');
    if(locale) {
      this.setState({
        language: locale,
        messages: (locale === 'en') ? enJson : zhJson
      })
    }

    this._isMounted = true;
    console.log("***path name: " + window.location.pathname);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateLocale(locale) {
    if(this._isMounted) {
      localStorage.setItem('samlovesdesignLocale', locale);
      this.setState({
        language: locale,
        messages: (locale === 'en') ? enJson : zhJson
      });
    }
  }

  render () {
    const { language, messages, expYear } = this.state;
    const globalSettings = this.getDocument('settings', 'global')
    const {
      siteTitle,
      siteUrl,
      siteDescription,
      socialMediaCard,
      headerScripts
    } = globalSettings

    const posts = this.getDocuments('posts').filter(
      post => post.status !== 'Draft'
    );

    const portfolios = this.getDocuments('portfolio').filter(
        portfolio => portfolio.status !== 'Draft'
    );

    const portfolioList = portfolios.map(i => ({ title: i.title, cover: i.cover }));

    const categoriesFromPosts = getCollectionTerms(posts, 'categories');
    const postCategories = this.getDocuments('postCategories').filter(
      category => categoriesFromPosts.indexOf(category.name.toLowerCase()) >= 0
    );

    return (
      <IntlProvider locale={ language } key={ language } messages={ messages }>
        <Router>
          <div className={`React-Wrap ${language}`}>
            <ScrollToTop />
            <ServiceWorkerNotifications reloadOnUpdate />
            <Helmet
                defaultTitle={siteTitle}
                titleTemplate={`${siteTitle} | %s`}
            />
            <Meta
                headerScripts={headerScripts}
                absoluteImageUrl={
                  socialMediaCard &&
                  socialMediaCard.image &&
                  siteUrl + socialMediaCard.image
                }
                twitterCreatorAccount={
                  socialMediaCard && socialMediaCard.twitterCreatorAccount
                }
                twitterSiteAccount={
                  socialMediaCard && socialMediaCard.twitterSiteAccount
                }
            />

            <Nav portfolioList={portfolioList} />

            <Switch>
              <RouteWithMeta
                  path='/'
                  exact
                  component={Home}
                  exp={expYear}
                  description={siteDescription}
                  portfolioList={portfolioList}
                  locale={language}
                  fields={this.getDocument('pages', 'home')}
              />
              <RouteWithMeta
                  path='/about/'
                  exact
                  component={ About }
                  exp={ expYear }
                  locale={ language }
                  fields={this.getDocument('pages', 'about')}
              />
              <RouteWithMeta
                  path='/contact/'
                  exact
                  component={Contact}
                  fields={this.getDocument('pages', 'contact')}
                  siteTitle={siteTitle}
              />
              <RouteWithMeta
                  path='/covid19/'
                  exact
                  component={COVID}
              />
              <RouteWithMeta
                  path='/more/d3/:name'
                  exact
                  component={D3}
              />
              {/*<RouteWithMeta*/}
                  {/*path='/portfolio/compandsave'*/}
                  {/*exact*/}
                  {/*component={ CompAndSave }*/}
                  {/*locale={ language }*/}
              {/*/>*/}
              {/*<RouteWithMeta*/}
                  {/*path='/portfolio/createathon'*/}
                  {/*exact*/}
                  {/*component={ CreateAThon }*/}
                  {/*locale={ language }*/}
              {/*/>*/}
              {/*<RouteWithMeta*/}
                  {/*path='/portfolio/freelance'*/}
                  {/*exact*/}
                  {/*component={ Freelance }*/}
                  {/*locale={ language }*/}
              {/*/>*/}
              {/*<RouteWithMeta*/}
                  {/*path='/portfolio/liveh2h'*/}
                  {/*exact*/}
                  {/*component={ LiveH2H }*/}
                  {/*locale={ language }*/}
              {/*/>*/}
              {/*<RouteWithMeta*/}
                  {/*path='/portfolio/nimbella'*/}
                  {/*exact*/}
                  {/*component={ Nimbella }*/}
                  {/*locale={ language }*/}
              {/*/>*/}
              {/*<RouteWithMeta*/}
                  {/*path='/portfolio/sanmateocounty'*/}
                  {/*exact*/}
                  {/*component={ SanMateoCounty }*/}
                  {/*locale={ language }*/}
              {/*/>*/}
              <RouteWithMeta
                  path='/blog/'
                  exact
                  component={Blog}
                  fields={this.getDocument('pages', 'blog')}
                  posts={posts}
                  postCategories={postCategories}
              />

              {portfolios.map((portfolio, index) => {
                const path = `/portfolio/${portfolio.title.replace(/\s/g, '-')}`;
                const nextPost = posts[index - 1]
                const prevPost = posts[index + 1]
                return (
                    <RouteWithMeta
                        key={path}
                        path={path}
                        exact
                        component={Portfolio}
                        fields={portfolio}
                        locale={ language }
                        nextPostURL={nextPost && slugify(`/blog/${nextPost.title}/`)}
                        prevPostURL={prevPost && slugify(`/blog/${prevPost.title}/`)}
                    />
                )
              })}

              {posts.map((post, index) => {
                const path = slugify(`/blog/${post.title}`)
                const nextPost = posts[index - 1]
                const prevPost = posts[index + 1]
                return (
                    <RouteWithMeta
                        key={path}
                        path={path}
                        exact
                        component={SinglePost}
                        fields={post}
                        nextPostURL={nextPost && slugify(`/blog/${nextPost.title}/`)}
                        prevPostURL={prevPost && slugify(`/blog/${prevPost.title}/`)}
                    />
                )
              })}

              {postCategories.map(postCategory => {
                const slug = slugify(postCategory.title)
                const path = slugify(`/blog/category/${slug}`)
                const categoryPosts = posts.filter(post =>
                    documentHasTerm(post, 'categories', slug)
                )
                return (
                    <RouteWithMeta
                        key={path}
                        path={path}
                        exact
                        component={Blog}
                        fields={this.getDocument('pages', 'blog')}
                        posts={categoryPosts}
                        postCategories={postCategories}
                    />
                )
              })}

              <Route render={() => <NoMatch siteUrl={siteUrl} />} />
            </Switch>
            <Footer locale={ this.updateLocale } current={ language} />
          </div>
        </Router>
      </IntlProvider>
    )
  }
}

export default App
