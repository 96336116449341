import React, { Component, Fragment } from 'react';

export default class TechScope extends Component{
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const { locale } = this.props;
    return(
        <Fragment>
          <h2 className="f30 semi_bold">
            { locale === 'en' ? 'Technical Scope' : '使用技術範圍' }
          </h2>
          <p className="f16 standard tech_wrap">
            {this.props.skills.map((res, index) => {
              return <span key={ index }>{ res.skill }</span>
            })}
          </p>
        </Fragment>

    )
  }
}
