import React, { Component, Fragment } from 'react';

export default class Title extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

  }

  render() {
    return(
        <Fragment>
          <h2 className="f48 light txt_g txt_uppercase">{ this.props.text }</h2>
          <div className="divider-20">&nbsp;</div>
        </Fragment>
    );
  }
}
