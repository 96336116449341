
export const fetchCovid = (country) => {
  const params = {
    f: 'json',
    where: `(Confirmed > 0) AND (Country_Region='${country}')`,
    returnGeometry: false,
    spatialRel: 'esriSpatialRelIntersects',
    outFields: '*',
    orderByFields: 'Confirmed desc,Country_Region asc,Province_State asc',
    outSR: 102100,
    resultOffset: 0,
    resultRecordCount: 250,
    cacheHint: true,
  };
  return fetch(`https://services1.arcgis.com/0MSEUqKaxRlEPj5g/arcgis/rest/services/ncov_cases/FeatureServer/1/query?${handleQS(params)}`).then(res => res.json())
};

const handleQS = obj => {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(obj).length; i += 1) {
    keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`);
  }
  return keyValuePairs.join('&');
};


