import React, { useEffect } from 'react';
import * as d3 from 'd3';

const Arc = () => {
    const radius = 300;

    useEffect(() => {    
        const width = document.querySelector('.container.main_content').clientWidth;
        const canvas = d3.select('div#arc-container')
            .append('svg')
            .attr('width', width)
            .attr('height', radius * 3)

        const group = canvas.append('g')
            .attr('class', 'group')
            .attr('transform', `translate(${width / 2}, ${radius})`)

        const top = canvas.append('g')
            .attr('transform', 'translate(20, 20)') 
        
        const pie = d3.pie()
            .sort(null)
            .value(d => d.value)

        const arc = d3.arc()
            .innerRadius(radius * 0.6)
            .outerRadius(radius * 0.3)
            
        const outerArc = d3.arc()
            .innerRadius(radius * 0.7)
            .outerRadius(radius * 0.7)

        const midAngle = (d) => {
            return d.startAngle + (d.endAngle - d.startAngle) / 2
        }

        d3.json('https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json').then((data, error) => {
            if (error) throw error;
            
            const navList = data.children.filter(i => i.name === 'GB' || i.name === 'PS2' || i.name === 'PS4' ).map(i => i.name);
            
            const handleClick = function () {
                const text = d3.select(this)
                    .text()
                const listData = data.children.filter(i => i.name === text)[0].children.map(i => ({...i, value: parseInt(i.value)}));
                render(listData);
            }
            
            const nav = top.selectAll('.nav')
                .data(navList)
                .enter()
                    .append('g')
                    .attr('class', 'nav')

            nav.append('text')
                .attr('x', (_, i) => 50 * i)
                .attr('y', 10)
                .attr('text-anchor', 'middle')
                .attr('font-size', '1.4em')
                .text(d => d)
                .style('cursor', 'pointer')
                .on('click', handleClick)


            // Render Function
            const render = (listData) => {
                const color = d3.scaleSequential()
                    .domain([d3.min(listData.map(i => i.value)), d3.max(listData.map(i => i.value))])
                    .interpolator(d3.interpolateViridis)

                // Pies
                group.selectAll('path.arc')
                    .data(pie(listData))
                    .join(
                        function (enter) {
                            return enter
                                .append('path')
                                    .attr('class', 'arc')
                                    .attr('fill', d => color(d.data.value))
                                    .attr('stroke', 'white')
                                    .attr('d', d => arc(d))
                        },
                        function (update) {
                            return update
                        },
                        function (exit) {
                            return exit
                                .remove()
                        },
                    )   
                    .transition().duration(1000)
                        .attr('fill', d => color(d.data.value))
                        .attr('d', function(d) {
                            return arc(d);
                        })
                
                // values
                group.selectAll('text.values')
                    .data(pie(listData))
                    .join(
                        function (enter) {
                            return enter
                                .append('text')
                                .attr("dy", ".35em")
                                .attr('text-anchor', 'middle')
                                .attr('fill', 'white')
                                .attr('class', 'values')
                                .attr('font-size', '1.4em')
                                .attr('transform', function (d) {
                                    const pos = arc.centroid(d).map(i => i * .9)
                                    return `translate(${pos})`
                                })
                        },
                        function (update) {
                            return update
                        },
                        function (exit) {
                            return exit
                                .remove()
                        }
                    )
                .transition().duration(1000)
                    .text(d => d.data.value)
                    .attr('transform', function (d) {
                        const pos = arc.centroid(d).map(i => i * .9)
                        return `translate(${pos})`
                    })  
                    

                // labels
                group.selectAll('text.labels')
                    .data(pie(listData))
                    .join(
                        function (enter) {
                            return enter
                                .append('text')
                                .attr('font-size', '1.4em')
                                .attr('fill', 'steelblue')
                                .attr('class', 'labels')
                                .attr('transform', d => {
                                    const pos = outerArc.centroid(d)
                                    pos[0] = radius * 0.8 * (midAngle(d) < Math.PI ? 1 : -1)
                                    return `translate(${pos})`
                                })
                                .style('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
                                .text((d) => d.data.name)
                        },
                        function (update) {
                            return update
                        },
                        function (exit) {
                            return exit
                                .remove()

                        },
                    )
                    .transition().duration(1000)
                        .attr('transform', function (d) {
                            const pos = outerArc.centroid(d)
                            pos[0] = radius * 0.8 * (midAngle(d) < Math.PI ? 1 : -1)
                            return `translate(${pos})`
                        })
                        .style('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
                    

                // Polylines
                group.selectAll('polyline.polylines')
                    .data(pie(listData))                
                    .join(
                        function (enter) {
                            return enter
                                .append('polyline')
                                .attr('fill', 'none')
                                .attr('stroke', 'darkGray')
                                .attr('class', 'polylines')
                                .attr('points', d => {
                                    const pos = outerArc.centroid(d)
                                    pos[0] = radius * 0.75 * (midAngle(d) < Math.PI ? 1 : -1)
                                    return [arc.centroid(d), outerArc.centroid(d), pos]
                                })
                        },
                        function (update) {
                            return update
                        },
                        function (exit) {
                            return exit
                                .remove()
                        },
                    )
                    .transition().duration(1000)
                        .attr('points', d => {
                            const pos = outerArc.centroid(d)
                            pos[0] = radius * 0.75 * (midAngle(d) < Math.PI ? 1 : -1)
                            return [arc.centroid(d), outerArc.centroid(d), pos]
                        })
            }

            render(data.children.filter(i => i.name === navList[0])[0].children.map(i => ({...i, value: parseInt(i.value)})))
        })
    })
    
    return(
        <div id="arc-container"/>
    )
}

export default Arc;
