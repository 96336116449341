import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { feature } from 'topojson-client';

const Map = () => {
    const mouseOver = function(d) {
        d3.selectAll(".Country")
            .transition()
            .duration(200)
            .style("opacity", .5)
        d3.select(this).select('.Country')
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("stroke", "white")
            .style("box-shadow", "")
        d3.select(this).select('.Country')
            .each(item => {
                d3.select(this.parentNode).select('.selected-country')
                    .text(`Country: ${item.properties.name}`)
                d3.select(this.parentNode).select('.selected-code')
                    .text(`Code: ${item.properties.code}`)
            })
    }
    
    const mouseLeave = function(d) {
        d3.selectAll(".Country")
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("stroke", "white")
            d3.select(this).select('text')
            .attr('fill', 'black')
        d3.select(this).select('.Country')
            .each(() => {
                d3.select(this.parentNode).select('.selected-country')
                    .text(`Country:`)
                d3.select(this.parentNode).select('.selected-code')
                    .text(`Code:`)
            })
    }

    useEffect(() => {  
        const width = document.querySelector('.container.main_content').clientWidth;
        const height = document.querySelector('.container.main_content').clientHeight - 80;

        const projection = d3.geoMercator()
            .translate([width / 2, height / 2])

        const path = d3.geoPath()
            .projection(projection)

        const container = d3.select('div#map-container').html('')
            
        const svg = container.append('svg')
            .attr('viewBox', [0, 0, width, height])
            .attr('width', width)
            .attr('height', height)

        const canvas = svg.append('g')
        
        d3.json('/countries-10m.json').then((data, error) => {
            
            if (error) {
                throw error;
            }

            const init = (type) => {
                const features = feature(data, data.objects[type || 'states']).features;
                const america = features.filter(country => {
                    return country.properties[type === 'counties' ? 'state' : 'code'] !== 'AK' && 
                        country.properties[type === 'counties' ? 'state' : 'code'] !== 'HI' && 
                        country.properties[type === 'counties' ? 'state' : 'code'] !== 'PR' && 
                        country.properties[type === 'counties' ? 'state' : 'code'] !== 'VI' &&
                        country.properties[type === 'counties' ? 'state' : 'code'] !== 'AS' &&
                        country.properties[type === 'counties' ? 'state' : 'code'] !== 'GU' &&
                        country.properties[type === 'counties' ? 'state' : 'code'] !== 'MP'
                })

                return {
                    type: 'FeatureCollection',
                    features: america
                }
            }

            let americaFeature = init();

            const bounds = d3.geoBounds(americaFeature);
            const center = d3.geoCentroid(americaFeature);
            
            const distance = d3.geoDistance(bounds[0], bounds[1]) * 0.6;
            const scale = height / distance / Math.sqrt(2);

            projection.scale(scale).center(center);

            // To generate maps
            const group = canvas.selectAll('.Country')
                .data(americaFeature.features)
                .enter()
                .append('g')
                    // .attr('class', item => item.properties[type === 'counties' ? 'state' : 'code'])
                    .on('mouseover', mouseOver)
                    .on('mouseleave', mouseLeave)

            group.append('path')
                .classed('country', true)
                .attr('d', path)
                .attr('class', 'Country')
                .attr('fill', 'steelblue')
                .attr('stroke', 'white')

            // To display selected country and code
            const selected = canvas.append('g')
                .attr('transform', 'translate(20, 30)')
        
            selected.append('text')
                .attr('class', 'selected-country')
                .text('Country: ')
            selected.append('text')
                .attr('transform', 'translate(20, 20)')
                .attr('class', 'selected-code')
                .text('Code: ')
            
            // To handle zoom in/out and drag maps
            const zoomed = ({transform}) => {
                if (transform.k >= 5) {
                    console.log('load counties')
                    canvas.exit().remove()
                    canvas
                        .data(americaFeature.features)
                        .enter()
                }
                group.attr('transform', transform)
            }
            svg.call(d3.zoom()
                .extent([[0, 0], [width, height]])
                .scaleExtent([1, 8])
                .on('zoom', zoomed)
            )
        });
        
    })
    
    return(
        <div id="map-container"/>
    )
}

export default Map;
