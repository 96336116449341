import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import '../stylesheets/components/AvatarAni.css'

const avatarAnimationMap = {
  blinkEverySecond: 3000,
  blinkDuration: 200,
  snoreEverySecond: 3000,
  snoreDuration: 2000,
  checkTimeDuration: 30000,
  avatarPositionY: -215
};


export default class AvatarAni extends Component{

  constructor(props) {
    super(props);

    this.state = {
      clientX: 0,
      clientY: 0,
      a1: 0,
      a2: 0,
      X1: 74,
      Y1: 118,
      X2: 131,
      Y2: 118,
      R: 10,
      avatarStyle: {},
      faceStyle: {},
      bubbleStyle: {}
    };

    this.currentHour = new Date().getHours();
    this.eyeLStyle = {};
    this.eyeRStyle = {};

    this.blinkInterval = null;
    this.snoreInterval = null;
    this.blinkTimeout = null;
    this.snoreTimeout = null;

    this._isMounted = false;

    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    let count = 0;
    this.checkTime();
    setInterval(() => {
      this.checkTime();
      if(this.currentHour >= 8 && this.currentHour < 22) {
        if(this.snoreInterval === null) {
          count++;
        }
        if(count > 1) {
          this.snoreOnly();
          count = 0;
        }
      }
    }, avatarAnimationMap['checkTimeDuration']);
  }

  handleMouseMove(e){
    if(this._isMounted) {
      let avatarEle = ReactDOM.findDOMNode(this.refs['avatarElement']).getBoundingClientRect();

      this.setState({
        clientX: e.nativeEvent.clientX,
        clientY: e.nativeEvent.clientY,
        a1: Math.atan2(this.state.clientX - this.state.X1 - avatarEle.left, this.state.clientY - this.state.Y1 - avatarEle.top),
        a2: Math.atan2(this.state.clientX - this.state.X2 - avatarEle.left, this.state.clientY - this.state.Y2 - avatarEle.top)
      });

      this.eyeLStyle = {
        left: this.state.R * Math.sin(this.state.a1) + this.state.X1 -10 + 'px',
        top: this.state.R * Math.cos(this.state.a1) + this.state.Y1 -10+ 'px'
      };
      this.eyeRStyle = {
        left: this.state.R * Math.sin(this.state.a2) + this.state.X2 -10 + 'px',
        top: this.state.R * Math.cos(this.state.a2) + this.state.Y2 -10 + 'px'
      };

      if(this.currentHour >= 8 && this.currentHour < 22) {
        this.blinkOnly();
      }
    }
  }

  blink() {
    if(this._isMounted) {
      this.blinkTimeout = setTimeout(() => {
        this.setState({
          avatarStyle: { backgroundPositionY: 0 }
        });
        clearTimeout(this.blinkTimeout);
      }, avatarAnimationMap['blinkDuration']);

      this.setState({
        avatarStyle: { backgroundPositionY: avatarAnimationMap['avatarPositionY'] + 'px' },
        faceStyle: { top: 0 },
        bubbleStyle: { transform: 'scale(' + 0 + ')' }
      });
    }
  }

  snore() {
    if(this._isMounted) {
      this.setState({
        avatarStyle: { backgroundPositionY: avatarAnimationMap['avatarPositionY'] + 'px' }
      });

      this.snoreTimeout = setTimeout(() => {
        this.setState({
          faceStyle: { top: 0 },
          bubbleStyle: { transform: 'scale(' + 0 + ')' }
        });
        clearTimeout(this.snoreTimeout);
      }, avatarAnimationMap['snoreDuration']);

      this.setState({
        faceStyle: { top: 6 + 'px' },
        bubbleStyle: { transform: 'scale(' + 1 + ')' }
      });
    }
  }

  setBlink() {
    this.blink();
    this.blinkInterval = setInterval(() => {
      this.blink()
    }, avatarAnimationMap['blinkEverySecond']);
  }

  setSnore() {
    this.snore();
    this.snoreInterval = setInterval(() => { this.snore() }, avatarAnimationMap['snoreEverySecond'])
  }

  cancelBlink() {
    if(this.blinkInterval !== null) {
      clearInterval(this.blinkInterval);
      this.blinkInterval = null;
    }
  }

  cancelSnore() {
    if(this.snoreInterval !== null) {
      clearInterval(this.snoreInterval);
      this.snoreInterval = null;
    }
  }

  blinkOnly() {
    this.cancelSnore();
    if(this.blinkInterval === null) {
      this.setBlink();
    }
  }

  snoreOnly() {
    this.cancelBlink();
    if(this.snoreInterval === null) {
      this.setSnore();
    }
  }

  checkTime() {
    if((this.currentHour >= 22 && this.currentHour <= 24) || (this.currentHour > 0 && this.currentHour < 8))
      this.snoreOnly();
    else
      this.blinkOnly();
  }

  render() {
    return(
        <div className="AvatarAni">
          <div className="mouseEvent_wrap" onMouseMove={ this.handleMouseMove }>&nbsp;</div>
          <div className="avatar_wrap" ref="avatarElement">
            <div className="face_wrap" style={ this.state.faceStyle }>
              <div className="avatar" style={ this.state.avatarStyle }>&nbsp;</div>
              <div className="eye-l" style={ this.eyeLStyle }>&nbsp;</div>
              <div className="eye-r" style={ this.eyeRStyle }>&nbsp;</div>
              <div className="bubble" style={ this.state.bubbleStyle }>&nbsp;</div>
            </div>
            <div className="body_wrap">
              <div className="body">&nbsp;</div>
            </div>
          </div>
        </div>
    );
  }
}
