import React, { useEffect } from 'react';
import * as d3 from 'd3';

const Histogram = () => {
    useEffect(() => {  
        const width = 400,
            containerWidth = document.querySelector('.container.main_content').clientWidth,
            height = 500,
            padding = 20
    
        d3.csv('/histogram.csv').then((data) => {
            const map = data.map(i => parseInt(i.age));
    
            const histogram = d3
                .bin()
                (map)
    
            const y = d3.scaleLinear()
                .domain([0, d3.max(histogram.map(i => i.length))])
                .range([0, height - padding])
    
            const x = d3.scaleLinear()
                .domain([0, histogram[histogram.length -1].x1])
                .range([0, width - padding * 2])
    
            const xAxis = d3.axisBottom(x)
            
            const canvas = d3.select('div#histogram-container')
                .html('')
                .append('svg')
                .attr('width', containerWidth)    
                .attr('height', height + padding)
                .append('g')
                    .attr('transform', `translate(${(containerWidth - width) / 2}, 0)`)
                
            canvas.append('g')
                .attr('transform', `translate(0, ${height})`)
                .call(xAxis)
    
            const bars = canvas.selectAll('.bar')
                .data(histogram)
                .enter()
                .append('g')
                    .attr('class', 'bar')
    
            bars.append('rect')
                .attr('x', d => x(d.x0))
                .attr('y', d => (height - y(d.length)))
                .attr('width', d => x(d.x1 - d.x0))
                .attr('height', d => y(d.length))
                .attr('fill', 'steelblue')
                .attr('stroke', 'white')
    
            bars.append('text')
                .attr('x', d => x(d.x0))
                .attr('y', d => (500 - y(d.length)))
                .attr('dx', d => x(d.x1 - d.x0) / 2)
                .attr('dy', padding)
                .attr('fill', 'white')
                .attr('text-anchor', 'middle')
                .text(d => d.length)
        })
    })
    
    return(
        <div id="histogram-container"/>
    )
}

export default Histogram;
