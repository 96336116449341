import React, { Component, Fragment } from 'react';
import Title from '../components/PortfolioTitle';
import Date from '../components/PortfolioDate';
import TechScope from '../components/PortfolioTechScope';
import Summary from '../components/PortfolioSummary';
import Case from '../components/PortfolioCase';
import Achievement from '../components/PortfolioAchievement';
import PortfolioContestDetails from "../components/PortfolioContestDetails";

import '../stylesheets/views/Portfolio.css';

export default class Portfolio extends Component{
  constructor(props) {
    super(props);

    this.state = { ...props }
  }

  componentDidMount() {
    window.scrollTo({top:0});
    console.log(this.state)
  }

  render() {
    const { fields, locale } = this.state;
    return(
        <section className="container main_content">
          <div className="detail_wrap">
            <div className="col-lg-10 offset-lg-1 detail_content">
              <Title text={ fields.title } />
              <Date from={ fields.dateFrom } to={ fields.dateTo } present={fields.present} locale={ locale } />
              <TechScope skills={ fields.techScope } locale={ locale } />

              { fields.summary && <Fragment>
                <div className="divider-20 tt">&nbsp;</div>
                <Summary title={ 'summary' } desc={ (this.props.locale === 'en') ? fields.summary.en : fields.summary.zh }/>
              </Fragment> }

              { fields.achievement && /Create-a-Thon/i.test(window.location.pathname) && <Fragment>
                <div className="divider-20 tt">&nbsp;</div>
                <PortfolioContestDetails list={ fields.achievement } locale={ locale } />
              </Fragment> }

              { fields.case && <Fragment>
                <div className="divider-20 test">&nbsp;</div>
                { fields.case.map((res, index) => {
                  return <Fragment key={ index }>
                    <Case title={ (this.props.locale === 'en')? res.title.en:res.title.zh }
                          desc={ (this.props.locale === 'en')? res.desc.en:res.desc.zh }
                          challenge={ res.challenge }
                          solution={ res.solution }
                          industry={ res.industry}
                          extLink={ res.extLink }
                          intLink={ res.intLink }
                          locale={locale}
                          img={ res.img } />
                    <div className="divider-40">&nbsp;</div></Fragment>
                }) }
              </Fragment> }

              {!/Create-a-Thon/i.test(window.location.pathname) && fields.achievement && <Fragment>
                <h2 className="f30 semi_bold">
                  { locale === 'en' ? 'Key Achievement' : '主要成就' }
                </h2>
                <Achievement list={ fields.achievement } locale={locale} />
              </Fragment>}

              <div className="divider-20">&nbsp;</div>
            </div>
          </div>
        </section>
    )
  }
}
