import React, { Component } from 'react';

export default class Achievement extends Component{
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <ul>
          {
            this.props.list.map((res, index) => {
              return <li className="f16 standard" key={ index }>{ (this.props.locale === 'en') ? res.item.en : res.item.zh }</li>
            })
          }
        </ul>
    )
  }
}
