import React, { useEffect } from 'react';
import * as d3 from 'd3';

const Tree = () => {

    useEffect(() => {  
        const width = document.querySelector('.container.main_content').clientWidth;
        const height = 500;

        const canvas = d3.select('div#tree-container')
            .html('')
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
                .attr('transform', 'translate(50, 50)')

        d3.json('https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json').then((data, error) => {
            if (error) throw error;

            const reducedData = {name: data.name, children: data.children.slice(10, data.children.length - 1)}
            const hierarchy = d3.hierarchy(reducedData)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value)

            const treemap = d3.tree()
                .size([width / 3, height])

            const root = treemap(hierarchy)

            const treeData = treemap(root)

            const nodes = treeData.descendants();
            const links = treeData.descendants().slice(1);

            const y = d3.scaleLinear()
                .domain([0, d3.max(links.map(i => i.x))])
                .range([0, height - 100])

            const x = d3.scaleLinear()
                .domain([0, d3.max(links.map(i => i.y))])
                .range([0, width - 300])

            const node = canvas.selectAll('.node')
                .data(nodes)
                .enter()
                    .append('g')
                    .attr('class', 'node')
                    .attr('transform', (item) => `translate(${x(item.y)}, ${y(item.x)})`)

            node.append('circle')
                .attr('r', 5)
                .attr('fill', 'steelblue')

            node.append('text')
                .attr('font-size', '1em')
                .attr('transform', 'translate(7, 3)')
                .text(item => item.data.name)

            const diagonal = d3.linkHorizontal()
                .source(d => [x(d.parent.y), y(d.parent.x)])
                .target(d => [x(d.y), y(d.x)])

            canvas.selectAll('.link')
                .data(links)
                .enter()
                    .append('path')
                    .attr('class', 'link')
                    .attr('fill', 'none')
                    .attr('stroke', '#ADADAD')
                    .attr('d', diagonal)
        })
        
    })
    
    return(
        <div id="tree-container"/>
    )
}

export default Tree;
