import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export default class Summary extends Component{
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <Fragment>
          <h2 className="f30 semi_bold">
            { (this.props.title === 'summary')? <FormattedMessage id="Project.summary" defaultMessage="Summary" />: <FormattedMessage id="Project.intro" defaultMessage="Introduction" />}

          </h2>
          <p className="f16 standard">{ this.props.desc }</p>
        </Fragment>

    )
  }
}
