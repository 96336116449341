import React, { useEffect } from 'react';
import * as d3 from 'd3';

const Bubble = () => {
    useEffect(() => {  
        const width = document.querySelector('.container.main_content').clientWidth;
        const height = 600;

        const extendedRadius = 0

        const getParent = (d) => {
            if (d.parent !== null) {
                return getParent(d.parent)
            }
            return d
        }

        const getOutterPos = (d) => {
            const {x: px, y: py, r: pr} = getParent(d);
            const cx = d.x
            const cy = d.y
            const radian = Math.atan2(cy - py, cx - px)
            const distance = Math.sqrt(Math.pow(cx - px, 2) + Math.pow(cy - py, 2))
            const nx = Math.cos(radian) * (pr - distance + extendedRadius)
            const ny = Math.sin(radian) * (pr - distance + extendedRadius)
            const pos = [nx, ny]
            return { nx, ny, pos }
        }

        const mouseover = function(...args) {
            const [color] = args
            
            // all circle opacity to 0.3
            d3.selectAll(".node")
                .transition()
                .duration(200)
                .style("opacity", .3)

            // selected circle opacity to 1
            d3.select(this)
                .transition()
                .duration(200)
                .style("opacity", 1)

            // show polyline
            d3.select(this)
                .append('polyline')
                .attr('fill', 'none')
                .attr('stroke', d => color(d?.parent?.children?.length))
                .attr('class', 'polyline')
                .attr('points', d => {
                    const { pos, nx, ny } = getOutterPos(d)
                    if (d.height === 0) {
                        return [[0, 0], pos, [nx - 40 * (d.x >= getParent(d).x ? -1 : 1), ny]]
                    }
                })

            // show text
            d3.select(this).select('text')
                .transition()
                .duration(0)
                .attr('font-size', '1.4em')
                .attr('fill', d => color(d?.parent?.children?.length))
                .attr('text-anchor', d => d.x >= getParent(d).x ? 'start' : 'end')
                .attr('transform', d => {
                    const { nx, ny } = getOutterPos(d)

                    if (d.height === 0) {
                        return `translate(${nx - 50 * (d.x >= getParent(d).x ? -1 : 1)}, ${ny})`
                    }
                })
                .text(d => {
                    if (d.height !== 0) return '';
                    return `${d.data.name} (${d.data.value})`;
                })
        }

        const mouseleave = function() {
            // all circles opacity to 1
            d3.selectAll(".node")
                .transition()
                .duration(200)
                .style("opacity", 1)

            // remove polyline
            d3.selectAll('.polyline').remove()

            // text back to normal
            d3.selectAll('.text')
                .attr('font-size', '1.1em')
                .attr('fill', 'white')
                .attr('text-anchor', 'middle')
                .attr('transform', 'translate(0, 0)')
                .text(d => {
                    const letterWidth = 8;
                    const circleWidth = d.r * 2;
                    if (d.children) return ''
                    if (d.data.name.length * letterWidth < circleWidth) {
                        return d.data.name
                    } else {
                        return `${d.data.name.substring(0, circleWidth / letterWidth)}...`
                    }
                })
        }

        const canvas = d3.select('div#bubble-container')
            .html('')
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
                .attr('transform', 'translate(50, 50)')    

        d3.json('https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json').then((data, error) => {
            if (error) throw error;

            const hierarchy = d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value)

            const pack = d3.pack()
                .size([width, height - 60])
                .padding(10)

            const root = pack(hierarchy)

            const lenArr = data.children.map(i => i.children.length)

            const color = d3.scaleLinear()
                .domain([d3.min(lenArr), d3.max(lenArr)])
                .range(['red', 'blue'])
            
            const node = canvas.selectAll('.node')
                .data(pack(root))
                .enter()
                .append('g')
                    .attr('class', 'node')
                    .attr('transform', d => `translate(${d.x}, ${d.y})`)
                    .on('mouseover', function (d) {
                        mouseover.apply(this, [color])
                    })
                    .on('mouseleave', mouseleave)

            // circle
            node.append('circle')
                .attr('r', d => d.r)
                .attr('fill', d => d.children ? 'white' : color(d.parent.children.length))
                .attr('opacity', 0.45)
                .attr('stroke', d => d.children ? 'white': color(d.parent.children.length))
                .attr('stroke-width', '1')

            // text
            node.append('text')
                .text(d => {
                    const letterWidth = 8;
                    const circleWidth = d.r * 2;
                    if (d.children) return ''
                    if (d.data.name.length * letterWidth < circleWidth) {
                        return d.data.name
                    } else {
                        return `${d.data.name.substring(0, circleWidth / letterWidth)}...`
                    }
                })
                .attr('class', 'text')
                .attr('dy', '0.3em')
                .attr('text-anchor', 'middle')
                .attr('fill', 'white')
                .attr('font-size', '1.1em')
        })
    })
    
    return(
        <div id="bubble-container"/>
    )
}

export default Bubble;
