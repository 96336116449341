import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone'

export default class Date extends Component {
  // eslint-disable-next-line
  render() {
    const { locale, from, to, present } = this.props;
    return(
        <p>
          <Moment locale={ locale } format='MM/YYYY'>{from}</Moment>&nbsp;-&nbsp;
          { (!present) ? <Moment locale={ locale } format='MM/YYYY'>{to}</Moment> : (locale === 'en' ? 'Present' : '現在') }
        </p>
    )
  }
}
