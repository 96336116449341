import React, { useEffect } from 'react';
import * as d3 from 'd3';

const Treemap = () => {

    useEffect(() => {  
        const width = document.querySelector('.container.main_content').clientWidth;
        const height = document.querySelector('.container.main_content').clientHeight - 80;

        const color = d3.scaleOrdinal(d3.schemeCategory10);

        const canvas = d3.select('div#treemap-container')
            .html('')
            .append('svg')
            .attr('width', width)
            .attr('height', height)

        d3.json('https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json').then((data, error) => {
            if (error) throw error;

            const hierarchy = d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value)

            const treemap = d3.treemap()
                .size([width, height])

            const root = treemap(hierarchy)

            const cells = canvas.selectAll('g')
                .data(root)
                .enter()
                .append('g')
                    .attr('class', 'cell')

            cells.append('rect')
                .attr('x', d => d.x0)
                .attr('y', d => d.y0)
                .attr('width', d => d.x1 - d.x0)
                .attr('height', d => d.y1 - d.y0)
                .attr('fill', d => color(d?.parent?.data?.name))
                .attr('stroke', 'white')

            cells.append('text')
                .attr('x', d => (d.x0 + d.x1) / 2)
                .attr('y', d => (d.y0 + d.y1) / 2)
                .attr('text-anchor', 'middle')
                .attr('font-size', '1em')
                .attr('fill', 'white')
                .text(d => {
                    const letterWidth = 9;
                    const cellWidth = d.x1 - d.x0;
                    if (d.data.name.length * letterWidth < cellWidth) {
                        return d.data.name
                    } else {
                        return `${d.data.name.substring(0, cellWidth / letterWidth)}...`
                    }
                })
        }) 
        
    })
    
    return(
        <div id="treemap-container"/>
    )
}

export default Treemap;
