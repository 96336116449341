import React, { Component } from 'react';

import AvatarAni from '../components/AvatarAni';
import { Link } from 'react-router-dom';

import '../stylesheets/views/Home.css';

export default class Body extends Component{
  render() {
    const { portfolioList = [], locale = 'en', fields: { position = '', desc1 = '', desc2 = '', btn = '' } } = this.props;
    return (
        <section className="Home container main_content">
          <div className="col-lg-10 offset-lg-1">
            <div className="row">
              <div className="divider-40">&nbsp;</div>

              <div className="col-sm-5 col-lg-3 left_wrap">
                {!/^\/admin/.test(window.location.pathname) && <AvatarAni />}
              </div>

              <div className="col-sm-7 col-lg-8 offset-lg-1 right_wrap">
                <div className="divider-20">&nbsp;</div>
                {position && <h1 className="f30 semi_bold">
                  { locale === 'en' ? position.en : position.zh }
                </h1>}
                {desc1 && desc2 && <p className="f16 standard">
                  { locale === 'en' ? desc1.en : desc1.zh } { this.props.exp } { locale === 'en' ? desc2.en : desc2.zh }
                </p>}
                <div className="btn_wrap space_top">
                  <Link to="/about">
                    {btn && <button className="btn btn_app">
                      { locale === 'en' ? btn.en : btn.zh }
                    </button>}
                  </Link>
                </div>
              </div>
            </div>

            <div className="divider-40" />
            <div className="clearfix" />
            <div className="row">
              {portfolioList.map((item, index) => (
                <div key={ index } className="col-sm-4 img_wrap">
                  <Link to={ '/portfolio/' + item.title.replace(/\s/g, '-') }>
                    <div className="name_wrap">
                      <h4><span className="f14 standard">{ item.title }</span></h4>
                    </div>
                  </Link>
                  <img src={ item.cover } alt={ item.title } width="100%" />
                </div>))
              }
            </div>
            <div className="divider-20" />
          </div>
        </section>
    )
  }
}
