import React, { Component, Fragment } from 'react';
import { isBrowser } from 'react-device-detect';

export default class Case extends Component{
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.handlerPopup = this.handlerPopup.bind(this);
  }

  fetchImage(file='', name={}, shadow=false, oriSize=false) {
    let _url = function() {
      if(typeof file === 'string' && file) {
        if(file.split('.')[1] === 'gif' || oriSize) {
          return 'ct_img gif_style';
        } else {
          if(shadow) {
            return 'ct_img';
          } else {
            return '';
          }
        }
      }
    };

    return (
        <img className={ _url() } src={ file } alt={  (this.props.locale === 'en' && name)? name.en : name.zh }/>
    )
  }

  handlerPopup() {
    let url = this.props.intLink.link;
    let h = this.props.intLink.height;
    let w = this.props.intLink.width;
    let opt = 'resizable=no, width=' + h + ', height=' + w + ', scrollbars=no';
    // if (/^http[s]?:\/\//.test(url)) {
      window.open(url, "newWindow", opt);
      console.log(url, opt)
    // } else {
      // window.open(url, "_blank");
      // console.log('Link is invalid: ' + url);
    // }
  }

  render() {
    const { locale } = this.props;
    return (
        <Fragment>
          <h3 className="f30 semi_bold">
            { this.props.title }-&nbsp;


            { (this.props.extLink )?
                <a className="link-style" href={ this.props.extLink } target="_blank" rel="noopener noreferrer">
                  { locale === 'en' ? 'View Site' : '觀看實際頁面' }
                </a>:null
            }

            { (this.props.intLink && isBrowser)?
                <span className="link-style" onClick={ this.handlerPopup }>
                  { locale === 'en' ? 'View Demo' : '觀看展示頁面' }
              </span>:null
            }
          </h3>

          <p className="f20 standard">{ this.props.desc }</p>

          { this.props.industry && <Fragment>
            <div className="divider-10">&nbsp;</div>
            <h3 className="f20 semi_bold">{ locale === 'en' ? 'Industry' : '行業種類' }-</h3>
            <p className="f16 standard">{ (this.props.locale === 'en') ? this.props.industry.en : this.props.industry.zh }</p>
          </Fragment>}

          { this.props.challenge && <Fragment>
            <div className="divider-10">&nbsp;</div>
            <h3 className="f20 semi_bold">
              { locale === 'en' ? 'Challenge' : '設計挑戰' }-
            </h3>
            <ul>
              { this.props.challenge.map((res, index) => {
                return (
                    <li className="f16 standard" key={ index }>{ (this.props.locale === 'en') ? res.item.en : res.item.zh }</li>
                )
              }) }
            </ul>
          </Fragment>}


          { this.props.solution && <Fragment>
            <div className="divider-10">&nbsp;</div>
            <h3 className="f20 semi_bold">
              { locale === 'en' ? 'Solution' : '解決方案' }-
            </h3>
            { (Object.prototype.toString.call(this.props.solution) === "[object Array]")?
                <ul>
                  { this.props.solution.map((res, index) => {
                    return (
                        <li className="f16 standard" key={ index }>{ (this.props.locale === 'en') ? res.item.en : res.item.zh }</li>
                    )
                  }) }
                </ul>:<p className="f20 standard">{ this.props.solution }</p>
            }
          </Fragment> }

          { this.props.img && <Fragment>

            { this.props.img.map((res, index) => (<Fragment key={ index }>
                <div className="divider-10">&nbsp;</div>
                <div className="img_wrap">
                  { res.name && res.name.en && (<p className="f20 semi_bold">{ (this.props.locale === 'en') ? res.name.en : res.name.zh }-</p>) }
                  { res.desc && res.desc.en && (<p className="f20 standard">{ (this.props.locale === 'en') ? res.desc.en : res.desc.zh }</p>) }
                  { this.fetchImage(res.file, res.name, res.shadow, res.originalSize) }
                  <div className="divider-10">&nbsp;</div>
                </div></Fragment>))
            }
          </Fragment> }


        </Fragment>
    )
  }
}
